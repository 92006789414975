import { UIMain } from "ftl-dashboards-templates"
import { Routes } from "./Routes"
import { AuthAPI, ProfileAPI } from "./repository"
import {
  
  basicReducer,
  handleError,
  initialState,
  logOutAction,
  setProfileAction,
} from "ftl-core"
import { createStore } from "redux"
import {
  FolderIcon,
  OrganizationIcon,
  PeopleIcon,
} from "ftl-uikit/dist/components/icons"
import {FTLLogoIcon, theme} from "ftl-uikit";


const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
const projectTitle = process.env.REACT_APP_FTL_PROJECT_TITLE
  ? process.env.REACT_APP_FTL_PROJECT_TITLE
  : "HR SaaS Admin"

export const App = () => {
  return (
    <UIMain
      project={{
        title: projectTitle,
      }}
      redirectOnAuthUrl="/departments"
      AuthClient={AuthAPI}
      theme={theme}
      StoreProviderProps={{
        store,
        getProfile: () => {
          //заглушка: единственный способ избежать вытягивание профиля при загрузке приложения
          return Promise.resolve({
            data: {
              result: {
                id: "",
                createdAt: "",
                email: "",
                firstName: "",
                lastName: "",
                accountStatus: "",
              },
            },
            status: 200,
            statusText: "OK",
            headers: {},
            config: {},
          })
        },
      }}
      // BaseLayoutProps={{
      //   onMenuClick: async () => {
      //     if (!store.getState().globalStore.profile?.id)
      //       try {
      //         const profile = (await ProfileAPI.get()).data.result
      //         store.dispatch(setProfileAction(profile))
      //       } catch (error) {
      //         handleError({
      //           error,
      //           defaultError: "Произошла ошибка при получении данных профиля",
      //         })
      //       }
      //   },

        // onSignOut: () => store.dispatch(logOutAction),
        //@ts-ignore
      BaseLayoutProps={{
        onMenuClick: async () => {
        },
        sideMenuItems: [
          {
            icon: <FolderIcon />,
            label: "Должности",
            to: "/ddd",
            items: [
              {
                label: "Структура",
                to: "/positions/struct",
              },
              {
                label: "Список",
                to: "/positions-list",
              },
            ],
          },
          {
            icon: <PeopleIcon />,
            label: "Сотрудники",
            to: "/employees",
          },
          {
            icon: <OrganizationIcon />,
            label: "Департаменты",
            to: "/departments",
          },
        ],
      }}
    >
      <Routes />
    </UIMain>
  )
}

export default App
