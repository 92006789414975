//components
import { Box } from "@material-ui/core"
import {
  FTLBreadcrumbs,
  FTLLoader,
  FTLPageHeader,
  FTLSelect,
  FTLTextField,
  FTLToolbar,
} from "ftl-uikit"

//types
import {
  DetailPageProps,
  handleError,
  snackbarService,
  useDetailPage,
  useFetchDefaults,
} from "ftl-core"

//other
import { initState, positionAvailableOnDepartmentSchema } from "./model"
import {
  baseUrl,
  DepartmentAPI,
  PositionAPI,
  PositionAvailableOnDepartmentAPI,
  repository,
  UtilsAPI,
} from "../../repository"
import { FTLDetailPage } from "ftl-dashboards-templates"
import { PositionAvailableOnDepartment } from "../../types/entities/positionAvailableOnDepartment"

const DetailPositionAvailableOnDepartmentPage = ({
  pageType,
}: DetailPageProps) => {
  const {
    id,
    isFetching,
    formik,
    history,
    isFormNotChanged,
    setIsFetching,
    setEntityData,
  } = useDetailPage({
    pageType,
    initialValues: initState,
    validationSchema: positionAvailableOnDepartmentSchema,
    getDetails: PositionAvailableOnDepartmentAPI.getById,
    handleFetchedEntity: async (positionAvailableOnDepartment) => {
      if (positionAvailableOnDepartment) {
        try {
          const [position, department] = await Promise.all([
            PositionAPI.getById(
              positionAvailableOnDepartment.data.result.positionId
            ),
            DepartmentAPI.getById(
              positionAvailableOnDepartment.data.result.departmentId
            ),
          ])
          const data = {
            ...formik.values,
            ...positionAvailableOnDepartment.data.result,
            positionTitle: position.data.result.title,
            departmentTitle: department.data.result.fullName,
          }
          await formik.setValues(data, false)
          setEntityData(data)
        } catch (error) {
          handleError({
            error,
            defaultError:
              "Произошла ошибка при получении данных должностей/департаментов",
            setIsFetching,
          })
        } finally {
          setIsFetching(false)
        }
      }
    },
    onSubmit: async (values) => {
      try {
        switch (pageType) {
          case "new":
            await PositionAvailableOnDepartmentAPI.create(values)
            await formik.setValues(
              {
                ...formik.values,
                departmentId: "",
                positionId: "",
              },
              false
            )
            snackbarService.show({
              variant: "success",
              message: "Должность успешно создана",
            })
            break
          case "edit":
            // await PositionAPI.edit(values as Position<"PATCH">)
            await repository.put(baseUrl + "/positions-available-on-department/" + id, values)
            snackbarService.show({
              variant: "success",
              message: "Параметры должности успешно обновлены",
            })
            break
        }
        history.push("/departments")
      } catch (error) {
        handleError({
          error,
          defaultError: "Произошла ошибка при отправке данных",
        })
      } finally {
        formik.setSubmitting(false)
      }
    },
  })

  useFetchDefaults({
    needToFetch: pageType === "new",
    getDefault: UtilsAPI.getDefault,
    defaults: ["positions", "departments"],
    handleDefaults: (defaults) => {
      if (defaults[0]) {
        formik.setFieldValue("positionId", defaults[0].id)
        formik.setFieldValue("positionTitle", defaults[0].positionTitle)
      }
      if (defaults[1]) {
        formik.setFieldValue("departmentId", defaults[1].id)
        formik.setFieldValue("departmentTitle", defaults[1].departmentTitle)
      }
    },
  })

  return (
    <FTLDetailPage<PositionAvailableOnDepartment<"FORMIK">>
      formik={formik}
      headerComponent={
        <>
          <FTLBreadcrumbs
            items={[
              { label: "Должности", to: "/positions-available-on-department" },
              {
                label:
                  pageType === "edit"
                    ? "Параметры должности"
                    : "Новая должность",
                to: "#",
              },
            ]}
          />

          <FTLPageHeader
            title={
              pageType === "edit" ? "Параметры должности" : "Новая должность"
            }
            BoxProps={{
              mb: 6,
            }}
          />
        </>
      }
      toolbarComponent={
        <FTLToolbar
          position="sticky"
          onSaveBtnId="form"
          onCancel={() => history.push("/departments")}
          async={formik.isSubmitting}
          disabled={isFormNotChanged}
          onSaveMessage="Сохранить"
        />
      }
    >
      {isFetching ? (
        <FTLLoader height="50vh" />
      ) : (
        <form
          noValidate
          id="form"
          onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns="360px 360px"
            gridGap="24px"
            mt={6}
          >
            <FTLSelect
              required
              label="Название департамента"
              placeholder="Выберите департамент из списка"
              error={
                formik.errors.departmentId && formik.submitCount > 0
                  ? true
                  : false
              }
              onChange={(option: any) => {
                formik.setFieldValue("departmentTitle", option?.label ?? "")
                formik.setFieldValue("departmentId", option?.value ?? "")
              }}
              value={
                formik.values.departmentId && formik.values.departmentTitle
                  ? {
                    value: formik.values.departmentId,
                    label: formik.values.departmentTitle,
                  }
                  : null
              }
              asyncData={{
                loadItems: (args) =>
                  DepartmentAPI.getList({
                    ...args,
                  }).catch((error) => {
                    handleError({
                      error,
                      defaultError:
                        "Ахтунг! Ахтунг!",
                    })
                    return error
                  }),
                normalizeScheme: { value: "id", label: "fullName" },
              }}
            />

            <FTLSelect
              required
              label="Название должности"
              placeholder="Выберите должность из списка"
              error={
                formik.errors.positionId && formik.submitCount > 0
                  ? true
                  : false
              }
              onChange={(option: any) => {
                formik.setFieldValue("positionTitle", option?.label ?? "")
                formik.setFieldValue("positionId", option?.value ?? "")
              }}
              value={
                formik.values.positionId && formik.values.positionTitle
                  ? {
                    value: formik.values.positionId,
                    label: formik.values.positionTitle,
                  }
                  : null
              }
              asyncData={{
                loadItems: (args) =>
                  PositionAPI.getList({
                    ...args,
                  }).catch((error) => {
                    handleError({
                      error,
                      defaultError:
                        "Ахтунг! Ахтунг!",
                    })
                    return error
                  }),
                normalizeScheme: { value: "id", label: "title" },
              }}
            />

            <FTLTextField
              name="salaryFrom"
              label="Заработная плата ОТ"
              placeholder="Введите сумму ОТ"
              value={formik.values.salaryFrom}
              onChange={formik.handleChange}
              error={
                formik.errors.salaryFrom &&
                  formik.submitCount > 0 &&
                  formik.touched.salaryFrom
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              fullWidth
            />

            <FTLTextField
              name="salaryUpTo"
              label="Заработная плата ДО"
              placeholder="Введите сумму ДО"
              value={formik.values.salaryUpTo}
              onChange={formik.handleChange}
              error={
                formik.errors.salaryUpTo &&
                  formik.submitCount > 0 &&
                  formik.touched.salaryUpTo
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              fullWidth
            />

            <FTLTextField
              name="salaryCondition"
              label="Прочие условия по заработной плате"
              placeholder="Введите описание прочих условий по зароботной плате"
              value={formik.values.salaryCondition}
              onChange={formik.handleChange}
              error={
                formik.errors.salaryCondition &&
                  formik.submitCount > 0 &&
                  formik.touched.salaryCondition
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
              fullWidth
            />
          </Box>
        </form>
      )}
    </FTLDetailPage>
  )
}

export default DetailPositionAvailableOnDepartmentPage
