import { Dictionary, handleError, SelectOption } from "ftl-core"

import { FetchAllArgs, NormalizeAllArgs } from "../../../types/entities"
import { PositionAPI } from "../../../repository"
import { Position } from "../../../types/entities/position"

export const normalizePositions = (
  { items, setItems }: NormalizeAllArgs<Position>,
  categoryId: string,
) => {
  const normalizedLabels: Dictionary<string> = {}
  const normalizedOptions: SelectOption<string>[] = []
  items.forEach((item: Position) => {
    if (item.title && item.id) {
      if (!categoryId || categoryId === item.categoryId) {
        normalizedLabels[item.id] = item.title
        normalizedOptions.push({
          value: item.id,
          label: item.title,
        })
      }
    }
  })
  setItems && setItems({ options: normalizedOptions, labels: normalizedLabels })
  return normalizedLabels
}

export const fetchAllPositions = async (
  {
    allItems,
    items,
    setItems,
    setAll,
    setIsFetching,
    labelProp,
    normalize = normalizePositions,
  }: Omit<FetchAllArgs<Position>, "items" | "normalize" | "setItems"> &
    Partial<Pick<FetchAllArgs<Position>, "items" | "setItems">> & {
    allItems?: Position[]
    setAll?: React.Dispatch<React.SetStateAction<Position[]>>
    normalize?: (
      { items, setItems }: NormalizeAllArgs<Position>,
      positionId: string,
    ) => Dictionary<string>
  },
  positionId: string = "",
): Promise<Dictionary<string>> => {
  if (!allItems?.length && !items)
    try {
      const responseItems = (await PositionAPI.getList({})).data.result.items
      setAll && setAll(responseItems)
      return normalize({ items: responseItems, setItems }, positionId)
    } catch (error) {
      handleError({
        error,
        defaultError: "Произошла ошибка при получении данных должностей",
        setIsFetching,
      })
      return {}
    }
  else
    return Promise.resolve(
      items
        ? items.labels
        : normalize({ items: allItems!, setItems, labelProp }, positionId),
    )
}