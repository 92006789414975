import React, { useEffect, useState } from "react"

//hooks
import { FTLPageHeader, FTLTable } from "ftl-uikit"

//types
import { FTLObjectUtils, useListPage, useLocalStoreState } from "ftl-core"
import { Cell } from "react-table"

//other
// import { positionActivityLabels } from "./model"
import { PositionAPI, PositionStructAPI } from "../../../repository"
import { FTLListPage } from "ftl-dashboards-templates"
import { normalizePositionStruct, normalizePositionStructFilters } from "./model"
import { useLocation } from "react-router-dom"

const columns = [
  {
    Header: "Наименование",
    accessor: "title",
    disableSortBy: true,
    width: 280,
  },
  {
    Header: "Тип",
    accessor: "type",
    disableSortBy: true,
    Cell: (props: Cell) => {
      const type: string = props.value
      return type === "POSITIONCATEGORY" ? "Категория должности" : "Должность"
    },
  },
  {
    Header: "Создан",
    accessor: "createdAt",
    align: "right",
    type: "date",
    reverse: true,
  }, {
    Header: "Обновлён",
    accessor: "updatedAt",
    align: "right",
    type: "date",
    reverse: true,
  },
  {
    Header: "",
    width: 80,
    accessor: "action",
    disableSortBy: true,
  },
]

const PositionStructPage = () => {

  let [parentCategoryId] = useState<string>("")
  let location = useLocation()

  const regExp = RegExp("[?&]parentCategoryId=", "ig")
  parentCategoryId = location.search.replaceAll(regExp, "")

  const [
    filters,
    tableSettings,
    setFilters,
    setTableSettings,
  ] = useLocalStoreState("positionStructList")

  const [rerenderState, setRerenderState] = useState<boolean>(false)

  useEffect(() => {
    const regExp = RegExp("[?&]parentCategoryId=", "ig")
    parentCategoryId = location.search.replaceAll(regExp, "")
  })

  const {
    data,
    pageCount,
    isFetching,
    history,
  } = useListPage(
    {
      filters,
      setFilters,
      tableSettings,
      getList: () => PositionStructAPI.getList({ parentCategoryId: parentCategoryId }),
      normalizeFilters: normalizePositionStructFilters,
      normalizeResponse: (items, context) =>
        normalizePositionStruct(items, context, rerenderState, setRerenderState),
      // getMeta: () => Promise.all([fetchPositionCategories()]),
    },
    [parentCategoryId, rerenderState, filters, tableSettings],
  )

  return (
    <FTLListPage
      headerComponent={
        <FTLPageHeader
          title="Структура должностей"
        />
      }
      tableComponent={
        <FTLTable
          data={data}
          columns={columns}
          pageCount={pageCount}
          isFetching={isFetching}
          tableState={tableSettings}
          setTableState={setTableSettings}
          actionsSize={2}
          isSearching={Boolean(FTLObjectUtils.getNotEmptyFieldsCount(filters))}
          onRowClick={(entity) => {
            if (entity.original.type === "POSITIONCATEGORY") {
              history.push(`/positions/struct?parentCategoryId=${entity.original.id}`)
            } else
              history.push(`/positions/${entity.original.id}`)
          }}
        />
      }
      filters={[]}
    />
  )
}
export default PositionStructPage
