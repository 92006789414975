import React, { lazy } from "react"
import { Switch } from "react-router-dom"
import { Box } from "@material-ui/core"

import { FTLProfilePage } from "ftl-dashboards-templates";
import { FTLErrorPage } from "ftl-uikit"
import { PrivateRoute } from "ftl-core"
import PositionAvailableOnDepartmentPage from "./pages/PositionAvailableOnDepartment/List"
import DetailPositionAvailableOnDepartmentPage from "./pages/PositionAvailableOnDepartment/Detail"
import PositionStructPage from "./pages/Positions/PositionStruct/List"
import { CountryAPI, PictureAPI, ProfileAPI, UtilsAPI } from "./repository"


//Profile
// const ProfilePage = lazy(
//   () => import("ftl-dashboards-templates"),
// )
const PositionsPage = lazy(() => import("./pages/Positions/List"))
const PositionCategoryPage = lazy(
  () => import("./pages/PositionCategories/List")
)
const DetailPositionPage = lazy(() => import("./pages/Positions/Detail"))
const DetailPositionCategoryPage = lazy(
  () => import("./pages/PositionCategories/Detail")
)
const DepartmentsPage = lazy(() => import("./pages/Departments/List"))
const DetailDepartmentPage = lazy(() => import("./pages/Departments/Detail"))


export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/profile">
        <FTLProfilePage
          getProfile={ProfileAPI.get}
          editProfile={ProfileAPI.edit}
          getCountry={CountryAPI.getById}
          getCountries={CountryAPI.getList}
          getDefaultCountry={() => UtilsAPI.getDefault("countries")}
          deleteProfilePicture={ProfileAPI.deletePicture}
          changeProfilePassword={ProfileAPI.changePassword}
          postPicture={PictureAPI.post}
        />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/positions-available-on-department/"
      >
        <PositionAvailableOnDepartmentPage />
      </PrivateRoute>
      <PrivateRoute exact path="/departments/detail/positions-available-on-department/new">
        <Box
          mr={15}
          ml={15}
          display="flex"
          flexDirection="column"
          style={{
            height: "100%",
          }}
        >
          <DetailPositionAvailableOnDepartmentPage pageType="new" />
        </Box>
      </PrivateRoute>
      <PrivateRoute exact path="/positions-available-on-department/:id">
        <Box
          mr={15}
          ml={15}
          display="flex"
          flexDirection="column"
          style={{
            height: "100%",
          }}
        >
          <DetailPositionAvailableOnDepartmentPage pageType="edit" />
        </Box>
      </PrivateRoute>

      {/*Position*/}

      <PrivateRoute exact path="/positions-list/">
        <PositionsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/positions/struct/:parentCategoryId?">
        <PositionStructPage />
      </PrivateRoute>
      <PrivateRoute exact path="/positions/new">
        <Box
          mr={15}
          ml={15}
          display="flex"
          flexDirection="column"
          style={{
            height: "100%",
          }}
        >
          <DetailPositionPage pageType="new" />
        </Box>
      </PrivateRoute>
      <PrivateRoute exact path="/positions/:id">
        <Box
          mr={15}
          ml={15}
          display="flex"
          flexDirection="column"
          style={{
            height: "100%",
          }}
        >
          <DetailPositionPage pageType="edit" />
        </Box>
      </PrivateRoute>

      {/*PositionCategories*/}

      <PrivateRoute exact path="/position-categories/">
        <PositionCategoryPage />
      </PrivateRoute>
      <PrivateRoute exact path="/position-categories/new">
        <Box
          mr={15}
          ml={15}
          display="flex"
          flexDirection="column"
          style={{
            height: "100%",
          }}
        >
          <DetailPositionCategoryPage pageType="new" />
        </Box>
      </PrivateRoute>
      <PrivateRoute exact path="/position-categories/:id">
        <Box
          mr={15}
          ml={15}
          display="flex"
          flexDirection="column"
          style={{
            height: "100%",
          }}
        >
          <DetailPositionCategoryPage pageType="edit" />
        </Box>
      </PrivateRoute>

      {/*// Departments*/}

      <PrivateRoute exact path="/departments/:parentDeps?">
        <DepartmentsPage />
      </PrivateRoute>

      <PrivateRoute exact path="/departments/detail/new">
        <DetailDepartmentPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/departments/detail/:id">
        <DetailDepartmentPage pageType="edit" />
      </PrivateRoute>


      <PrivateRoute path={["*", "/404"]}>
        <FTLErrorPage
          title="Страницы не существует"
          message="Наверное, вы перешли по неправильной ссылке."
        />
      </PrivateRoute>


    </Switch>
  )
}
