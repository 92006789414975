import * as Yup from "yup"

//types
import { Denormalized, FiltersState, FTLDateUtils, history, requiredMessage } from "ftl-core"
import { Box } from "@material-ui/core"
import { EditIcon, FTLIconButton } from "ftl-uikit"
import React from "react"
import { PositionStruct } from "../../../types/entities/positionStruct"

export const initState: PositionStruct = {
  title: "",
  parentCategoryId: "",
  type: "",
  createdAt: "",
  updatedAt: "",
}

export const sortableKeys = [
  {
    value: "createdAt",
    label: "Дата создания",
  },
  {
    value: "title",
    label: "Название",
  },
]

export const positionStructSchema = Yup.object({
  title: Yup.string().required(requiredMessage),
})

export const normalizePositionStructFilters = (filters: FiltersState) => ({
  query: filters.query,
})

export const normalizePositionStruct: (
  { items }: Denormalized<PositionStruct<"GET">>,
  context: { setData: React.Dispatch<PositionStruct<"GET">[]>; meta?: any },
  rerenderState: boolean,
  setRerenderState: Function,
) => {
  createdAt: string
  title: string
  parentCategoryId: string
  type: string
  action: JSX.Element
}[] = (
  { items }: Denormalized<PositionStruct<"GET">>,
  context: {
    setData: React.Dispatch<PositionStruct<"GET">[]>
    meta?: any
  },
  rerenderState: boolean,
  setRerenderState: Function,
) => {
  const meta = context.meta
  const normalizeDataOfTable = items.map((item, index) => {
    return {
      ...item,
      // type: item.type === "POSITIONCATEGORY"?"Категория должности":"Должность",
      // positionCategoryName: meta[0] && meta[0][item.categoryId],
      createdAt: FTLDateUtils.format(new Date(item?.createdAt ?? ""), {
        breakHours: false,
      }),
      updatedAt: FTLDateUtils.format(new Date(item?.updatedAt ?? ""), {
        breakHours: false,
      }),
      action: (
        <Box display="flex" justifyContent="center" alignItems="center">
          <FTLIconButton
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation()
              if (item.type === "POSITIONCATEGORY") {
                history.push(`/position-categories/${item.id}`)
              } else
                history.push(`/positions/${item.id}`)
            }}
          >
            <EditIcon />
          </FTLIconButton>
        </Box>
      ),
    }
  })
  return normalizeDataOfTable
}
















