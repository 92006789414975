import * as Yup from "yup"

import { Denormalized, dialogService, FiltersState, FTLDateUtils, history, requiredMessage } from "ftl-core"
import { Box } from "@material-ui/core"
import { PositionAvailableOnDepartment } from "../../types/entities/positionAvailableOnDepartment"
import { EditIcon, FTLIconButton, TrashIcon } from "ftl-uikit"
import React from "react"
import { baseUrl, repository } from "../../repository"

export const initState: PositionAvailableOnDepartment = {
  departmentId: "",
  positionId: "",
  salaryFrom: "",
  salaryUpTo: "",
  currency: "",
  salaryCondition: "",
  createdAt: "",
}

export const positionAvailableOnDepartmentSchema = Yup.object({
  departmentId: Yup.string().required(requiredMessage),
  positionId: Yup.string().required(requiredMessage),
})

export const normalizePositionAvailableOnDepartmentFilters = (filters: FiltersState) => ({
  query: filters.query,
  departmentId: filters.departmentId?.value,
})

export const normalizePositionAvailableOnDepartments: (
  { items }: Denormalized<PositionAvailableOnDepartment<"GET">>,
  context: { setData: React.Dispatch<PositionAvailableOnDepartment<"GET">[]>; meta?: any },
  rerenderState: boolean,
  setRerenderState: Function,
) => {
  // departmentName: any;
  salaryUpTo: string;
  // positionTitle?: string;
  // departmentTitle?: string;
  departmentId: string;
  salaryCondition: string;
  salaryFrom: string;
  positionName: any;
  createdAt: string;
  positionId: string;
  action: JSX.Element;
  currency: string;
  id: string
}[] = (
  { items }: Denormalized<PositionAvailableOnDepartment<"GET">>,
  context: {
    setData: React.Dispatch<PositionAvailableOnDepartment<"GET">[]>
    meta?: any
  },
  rerenderState: boolean,
  setRerenderState: Function,
) => {
  const meta = context.meta
  const normalizeDataOfTable = items.map((item, index) => {

    return {
      ...item,
      departmentName: meta[0] && meta[0][item.departmentId],
      positionName: meta[1] && meta[1][item.positionId],
      createdAt: FTLDateUtils.format(new Date(item?.createdAt ?? ""), {
        breakHours: false,
      }),
      action: (
        <Box display="flex" justifyContent="center" alignItems="center">
          <FTLIconButton
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation()
              history.push(`/positions-available-on-department/${item.id}`)
            }}
          >
            <EditIcon />
          </FTLIconButton>

          <FTLIconButton
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation()
              dialogService.show({
                onSubmit: async () => {
                  await repository.delete(`${baseUrl}/positions-available-on-department/${item.id}`)
                  dialogService.close()
                  setRerenderState(!rerenderState)
                },
                children: "Вы действительно хотите удалить должность?",
              })
            }}
          >
            <TrashIcon />
          </FTLIconButton>
        </Box>
      ),
    }
  })
  return normalizeDataOfTable
}
