import { AxiosResponse } from "axios"

//types
import {
  createCountryRepository,
  createEntityRepository,
  createProfileRepository,
  createRepositoryInstance,
  createUtilsRepository,
} from "ftl-core"
import { Position, PositionRequest } from "../types/entities/position"
import {
  PositionCategory,
  PositionCategoryRequest,
} from "../types/entities/positionCategory"
import { Department, DepartmentRequest } from "../types/entities/department"
import {
  PositionAvailableOnDepartment,
  PositionAvailableOnDepartmentRequest,
} from "../types/entities/positionAvailableOnDepartment"
import { PositionStruct, PositionStructRequest } from "../types/entities/positionStruct"
import { AuthClient } from "ftl-core"

enum Versions {
  v1 = "v1"
}


export const authApiBaseUrl = process.env.REACT_APP_FTL_AUTH_BASE_URL
  ? process.env.REACT_APP_FTL_AUTH_BASE_URL
  : "http://localhost:9102"
export const apiBaseUrl = process.env.REACT_APP_FTL_CRM_BASE_URL
  ? process.env.REACT_APP_FTL_CRM_BASE_URL
  : "http://localhost:9090"

export const baseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? `${apiBaseUrl}/api/${Versions.v1}`
    : `${apiBaseUrl}/api/${Versions.v1}`



export const repository = createRepositoryInstance({
  baseUrl,
  authErrors: {
    reasonKey: "reason",
    tokenExpired: ["ACCESS_TOKEN_EXPIRED"],
    permissionInsufficient: ["CREDENTIAL_PERMISSION_INSUFFICIENT"],
    needToLogin: [
      "CREDENTIAL_BLOCKED",
      "ACCESS_TOKEN_INACTIVATED",
      "ACCESS_TOKEN_NOT_EXIST",
      "ACCESS_TOKEN_REFRESHED",
      "ACCESS_TOKEN_MODIFIED",
      "ACCESS_TOKEN_MALFORMED",
    ],
  },
  tokenRefresh: () => AuthAPI.refresh(),
})

export const AuthAPI = new AuthClient(    
  repository,
  {
    signIn: `${authApiBaseUrl}/not-secure/api/v1/auth/login/email`,
    signOut: `${authApiBaseUrl}/not-secure/api/v1/auth/logout`,
    refresh: `${authApiBaseUrl}/not-secure/api/v1/auth/refresh`,
  },
  {
    loginPropName: "email",
  },
)

export const UtilsAPI = createUtilsRepository(repository)

//Profile

export const ProfileAPI = createProfileRepository(repository)

//Country

export const CountryAPI = createCountryRepository(repository, UtilsAPI)

//Picture

export const PictureAPI = Object.freeze({
  post: (request: FormData): Promise<AxiosResponse<{ result: string }>> => {
    return repository.post(`pictures`, request)
  },
})

// Positions

const normalizePositionPayload = (payload: Position): Position => ({
  categoryId: payload.categoryId,
  condition :{
  hardSkills: payload.condition.hardSkills,
  responsibilities: payload.condition.responsibilities,
  kpi: payload.condition.kpi,
  otherRequirements: payload.condition.otherRequirements,
  salaryFrom: payload.condition.salaryFrom,
  salaryUpTo: payload.condition.salaryUpTo,
  currency: payload.condition.currency,
  salaryCondition: payload.condition.salaryCondition,
},
  description: payload.description,
  title: payload.title,
  createdAt: payload.createdAt,
})

export const PositionAPI =
  createEntityRepository<PositionRequest,
  Position<"GET">,
  Position,
  Position<"PATCH">>({
  repositoryInstance: repository,
  basePath: "positions",
  utils: UtilsAPI,
  normalizeEntityPayload: normalizePositionPayload,
})

const normalizePositionStructPayload = (payload: PositionStruct): PositionStruct => ({
  title: payload.title,
  parentCategoryId: payload.parentCategoryId,
  type: payload.type,
  createdAt: payload.createdAt,
  updatedAt: payload.updatedAt,
})

export const PositionStructAPI =
  createEntityRepository<PositionStructRequest,
  PositionStruct<"GET">,
  PositionStruct,
  PositionStruct<"PATCH">>({
    repositoryInstance: repository,
    basePath: "positions/struct",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizePositionStructPayload,
  })


// PositionCategories

const normalizePositionCategoryPayload = (
  payload: PositionCategory
): PositionCategory => ({
  title: payload.title,
  parentId: payload.parentId,
  description: payload.description,
})

export const PositionCategoryAPI = createEntityRepository<PositionCategoryRequest,
  PositionCategory<"GET">,
  PositionCategory,
  PositionCategory<"PATCH">>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizePositionCategoryPayload,
  basePath: "position-categories",
  utils: UtilsAPI,
})
// ,
// getList: (params: PositionCategoryRequest) => {
//   return UtilsAPI.getList("/position-categories", params)
// },
// delete: (id: PositionCategory["id"]) => {
//   return repository.delete(`/position-categories/` + id)
// },
//
// }

// Departments

const normalizeDepartmentPayload = (payload: Department): Department => ({
  shortName: payload.shortName,
  parentDeps: payload.parentDeps,
  leaderIds: payload.leaderIds,
  description: payload.description,
  address: payload.address,
  fullName: payload.fullName,
  createdAt: payload.createdAt,
})


export const DepartmentAPI = Object.freeze({
  ...createEntityRepository<DepartmentRequest, Department<"GET">>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeDepartmentPayload,
  basePath: "departments",
  utils: UtilsAPI,
}),
  delete: (id: string) => repository.delete(`/departments/` + id),
  edit: (values: any) => repository.put(baseUrl + "/departments/" + values.id, values)
})


// Position Available On Department

const normalizePositionAvailableOnDepartmentPayload = (
  payload: PositionAvailableOnDepartment
): PositionAvailableOnDepartment => ({
  departmentId: payload.departmentId,
  positionId: payload.positionId,
  salaryFrom: payload.salaryFrom,
  salaryUpTo: payload.salaryUpTo,
  currency: payload.currency,
  salaryCondition: payload.salaryCondition,
})


export const PositionAvailableOnDepartmentAPI = createEntityRepository<PositionAvailableOnDepartmentRequest,
  PositionAvailableOnDepartment<"GET">,
  PositionAvailableOnDepartment,
  PositionAvailableOnDepartment<"PATCH">>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizePositionAvailableOnDepartmentPayload,
  basePath: "positions-available-on-department",
  utils: UtilsAPI,
})


