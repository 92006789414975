import React, { useCallback, useState } from "react"

//hooks
import { FTLPageHeader, FTLTable } from "ftl-uikit"

import { FTLListPage } from "ftl-dashboards-templates"

//types
import { history, useListPage, useLocalStoreState } from "ftl-core"
import { PositionAvailableOnDepartmentAPI } from "../../repository"
import { normalizePositionAvailableOnDepartmentFilters, normalizePositionAvailableOnDepartments } from "./model"
import { fetchAllPositions } from "../../shared/util/entities/positions"
import { MetaEntityState } from "../../types/entities"
import { fetchAllDepartments } from "../../shared/util/entities/departments"


const columns = [
  {
    Header: "Департамент",
    accessor: "departmentName",
    disableSortBy: true,
    // width: 280,
  },
  {
    Header: "Должность",
    accessor: "positionName",
    disableSortBy: true,
  },
  {
    Header: "З/П от",
    accessor: "salaryFrom",
    disableSortBy: true,
    // width: 280,
  },
  {
    Header: "З/П до",
    accessor: "salaryUpTo",
    disableSortBy: true,
  },
  {
    Header: "",
    width: 80,
    accessor: "action",
    disableSortBy: true,
  },
]

const PositionAvailableOnDepartmentPage = ({ departmentId }: any) => {
  const [rerenderState, setRerenderState] = useState<boolean>(false)
  const [positions, setPositions] = useState<MetaEntityState | null>(null)
  const [departments, setDepartments] = useState<MetaEntityState | null>(null)


  const fetchPositions = useCallback(
    () =>
      fetchAllPositions({
        items: positions,
        setItems: setPositions,
      }),
    [positions],
  )


  const fetchDepartments = useCallback(
    () =>
      fetchAllDepartments({
        items: departments,
        setItems: setDepartments,
      }),
    [departments],
  )


  const [
    filters,
    tableSettings,
    setFilters,
    setTableSettings,
  ] = useLocalStoreState("positionAvailableOnDepartmentList")

  const {
    data,
    pageCount,
    isFetching,
  } = useListPage(
    {
      filters,
      setFilters,
      tableSettings,
      getList: () => PositionAvailableOnDepartmentAPI.getList({ limit: 1000, departmentId: departmentId }),
      // getList: (request) => {
      //   return PositionAvailableOnDepartmentAPI.getList({ ...request, departmentId: departmentId })
      // },
      normalizeFilters: normalizePositionAvailableOnDepartmentFilters,
      normalizeResponse: (items, context) =>
        normalizePositionAvailableOnDepartments(items, context, rerenderState, setRerenderState),
      getMeta: () => Promise.all([fetchDepartments(), fetchPositions()]),
    },
    [rerenderState, filters, tableSettings],
  )

  return (
    <FTLListPage
      headerComponent={
        <FTLPageHeader
          title="Должности"
          buttons={[
            {
              children: "Новая должность",
              onClick: () => history.push("positions-available-on-department/new"),
            },
          ]}
        />
      }
      tableComponent={
        <FTLTable
          columns={columns}
          data={data}
          pageCount={pageCount}
          isFetching={isFetching}
          tableState={tableSettings}
          setTableState={setTableSettings}
        />
      }
    />
  )

}
export default PositionAvailableOnDepartmentPage